<template>
  <v-card class="py-3" flat outlined>
    <v-card-title v-if="label">
      {{ label }}
    </v-card-title>
    <v-row justify="center" class="mt-3">
      <slot name="additionalInfo" />
    </v-row>
    <v-row justify="center" align="center" class="px-3">
      <!-- Display each uploaded file with options to view or remove -->
      <v-col
        cols="12"
        v-for="(file, index) in fileList"
        :key="index"
        class="my-2"
      >
        <v-row class="d-flex align-center">
          <v-col cols="9">
            <v-icon v-if="isImage(file)">insert_photo</v-icon>
            <v-icon v-else>description</v-icon>
            <span class="ml-2">{{ file.name }}</span>
          </v-col>

          <v-col cols="3" class="d-flex" style="justify-content: flex-end">
            <v-btn small class="mr-2" @click="openFile(file)">
              <v-icon color="info">visibility</v-icon>
            </v-btn>
            <v-btn small color="error" @click="removeFile(index)">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">
        <v-btn v-if="fileList.length > 0" color="error" @click="clearAllFiles">
          Limpar Todos
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn
          :loading="uploading"
          color="success"
          @click="$refs.inputUpload.click()"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
      <input
        v-show="false"
        ref="inputUpload"
        type="file"
        :accept="inputAccept"
        multiple
        @change="load"
      />
    </v-row>
  </v-card>
</template>

<script>
import imageCompression from "browser-image-compression";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    inputAccept: {
      type: String,
      default: "image/jpeg,image/gif,image/png,application/pdf",
    },
  },
  data: () => ({
    uploading: false,
  }),
  computed: {
    fileList() {
      return this.value;
    },
    buttonText() {
      return (this.fileList.length > 0 ? "Adicionar" : "Novo") + " Arquivo";
    },
  },
  methods: {
    async load() {
      const files = Array.from(this.$refs.inputUpload.files);

      // Process each file and add to the list
      const processedFiles = await Promise.all(
        files.map(async (file) => {
          if (file.type === "application/pdf") {
            return file;
          } else {
            const compressedImage = await imageCompression(file, {
              maxSizeMB: 1,
              useWebWorker: true,
            });
            return compressedImage;
          }
        })
      );

      // Emit the new file list to parent
      this.$emit("input", [...this.fileList, ...processedFiles]);
    },
    openFile(file) {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl);
    },
    removeFile(index) {
      const newFileList = [...this.fileList];
      newFileList.splice(index, 1);
      this.$emit("input", newFileList);
    },
    clearAllFiles() {
      this.$emit("input", []);
    },
    isImage(file) {
      return file.type.startsWith("image/");
    },
  },
};
</script>

<style>
.text-center {
  text-align: center;
}
</style>
