<template>
  <view-tracking-impaction-point v-bind="$props" class="pb-5">
    <template v-if="trackingPoint.hasSides" #trackingPointContainer>
      <div class="d-flex justify-center">
        <property-value
          property="Lado(s) selecionado(s)"
          :value="trackingPointSide | formatPointSides"
        />
      </div>
    </template>
    <template v-if="impactionPoint.hasSides" #impactionPointContainer>
      <div class="d-flex justify-center">
        <property-value
          property="Lado(s) selecionado(s)"
          :value="impactionPointSide | formatPointSides"
        />
      </div>
    </template>
  </view-tracking-impaction-point>
</template>

<script>
export default {
  name: "TrackingImpactionPointViewSides",
  props: {
    impactionPoint: {
      type: Object,
      default: () => ({
        name: "",
        image: "",
      }),
    },
    trackingPoint: {
      type: Object,
      default: () => ({
        name: "",
        image: "",
      }),
    },
    trackingPointSide: {
      type: String,
      default: "",
    },
    impactionPointSide: {
      type: String,
      default: "",
    },
    justify: {
      type: String,
      default: "center",
    },
  },
};
</script>

<style scoped></style>
