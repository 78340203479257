import { findAll } from "@/services/local/resolution-zones-service.js";
import { findOne as findOnePoint } from "@/services/local/points-service.js";

const state = {
  resolutionZones: [],
  returnAction: "",
  validateAction: "",
};

const mutations = {
  setResolutionZones(state, value) {
    state.resolutionZones = value;
  },
  setReturnAction(state, value) {
    state.returnAction = value;
  },
  setValidateAction(state, value) {
    state.validateAction = value;
  },
  clearActions(state) {
    state.returnAction = "";
    state.validateAction = "";
  },
};

const actions = {
  async load({ commit }) {
    const resolutionZones = await findAll();

    const promises = resolutionZones.map(async (cz) => {
      const { impactionPointId, trackingPointId, ...rest } = cz;
      const impactionPoint = await findOnePoint(impactionPointId);
      const trackingPoint = await findOnePoint(trackingPointId);
      return {
        impactionPoint,
        trackingPoint,
        ...rest,
      };
    });

    const resolutionZonesWithPoints = await Promise.all(promises);
    commit("setResolutionZones", resolutionZonesWithPoints);
  },
  showDialog({ commit }, { returnAction, validateAction }) {
    commit("dialogs/setResolutionZones", true, { root: true });
    if (validateAction) {
      commit("setValidateAction", validateAction);
    }
    commit("setReturnAction", returnAction);
  },
  async callReturnAction({ state, dispatch }, diagnoseResolutionZone) {
    dispatch(state.returnAction, diagnoseResolutionZone, { root: true });
  },
  callValidateAction({ state, dispatch }, diagnoseResolutionZone) {
    if (state.validateAction) {
      console.log("validateAction", state.validateAction);
      return dispatch(state.validateAction, diagnoseResolutionZone, {
        root: true,
      });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
