import { findByIds } from "@/services/local/pares-biomagneticos-service";

const state = {
  added: [],
  selected: {},
};

const mutations = {
  addRelatedParesToAddedTumoralPhenomena(state, { index, relatedParesIds }) {
    state.added[index].relatedPares = relatedParesIds.map((id) => id);
  },
  addToDiagnose(state, tumoralPhenomena) {
    state.added.push(tumoralPhenomena);
  },
  removeFromDiagnose(state, index) {
    state.added.splice(index, 1);
  },
  changeAffectedSpotParTrauma(state, { index, newValue }) {
    state.diagnose.tumoralPhenomena[
      index
    ].diagnoseResolutionZone.affectedSpotParTrauma = newValue;
  },
  clear(state) {
    state.added = [];
  },
  setSelected(state, diagnoseTumoralPhenomena) {
    state.selected = diagnoseTumoralPhenomena;
  },
};

const actions = {
  addRelatedParesToDiagnose(
    { state, commit },
    { relatedParesIds, tumoralPhenomenonId }
  ) {
    const index = state.added.findIndex(
      (diagnoseTp) => diagnoseTp.tumoralPhenomenon.id === tumoralPhenomenonId
    );
    commit("addRelatedParesToAddedTumoralPhenomena", {
      index,
      relatedParesIds,
    });
  },
  async selectOne({ state, commit }, index) {
    const selectedDiagnoseTumoralPhenomena = state.added[index];
    const populatedRelatedPares = await findByIds(
      selectedDiagnoseTumoralPhenomena.relatedPares.map((par) => par.id)
    );
    commit("setSelected", {
      ...selectedDiagnoseTumoralPhenomena,
      populatedRelatedPares,
    });
  },
};

const getters = {
  hasSomeAdded(state) {
    return state.added.length > 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
