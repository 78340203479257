import dayjs from "dayjs";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import {
  createAppointment,
  deleteAppointment,
  getAppointmentsByTherapist,
  updateAppointment,
  createAppointMentWithPatient,
} from "@/services/external/appointments-service";
import Vue from "vue";
import { transformDatesStartAndEndToCalendarFormat } from "@/utils/appointment";

dayjs.extend(customParseFormatPlugin);

const initialStateTimeForm = {
  date: "",
  startAt: "",
  endAt: "",
  isPublic: true,
  patientName: "",
  status: 0,
};

const state = {
  appointments: [],
  timeForm: initialStateTimeForm,
  dialogTime: false,
  patient: null,
};

const mutations = {
  addAppointment(state, appointment) {
    state.appointments.push(appointment);
  },
  removeAppointment(state, index) {
    state.appointments.splice(index, 1);
  },
  replaceAppointment(state, { index, appointment }) {
    state.appointments.splice(index, 1, appointment);
  },
  setAppointments(state, appointments) {
    state.appointments = appointments;
  },
  setDialogTime(state, value) {
    state.dialogTime = value;
  },
  setTimeForm(state, value) {
    state.timeForm = value;
  },
  setPropertyTimeForm(state, { property, value }) {
    state.timeForm[property] = value;
  },
  cleanTimeForm(state) {
    state.timeForm = initialStateTimeForm;
  },
  changeAppointmentStatusToCancelled(
    state,
    { appointmentId, newStatus, reason, source }
  ) {
    const index = state.appointments.findIndex(
      (appointment) => appointment.id === appointmentId
    );
    Object.assign(state.appointments[index], {
      status: newStatus,
      cancellationReason: reason,
      cancellationSource: source,
    });
  },
  setPatient(state, patient) {
    state.patient = patient;
  },
};

const actions = {
  openTimeDialogToCreate({ commit }, { date, startAt, endAt, status }) {
    commit("setTimeForm", {
      id: null,
      date,
      startAt,
      endAt,
      status,
      isPublic: true,
    });

    commit("setDialogTime", true);
    commit("setPatient", null);
  },
  openTimeDialogToEdit(
    { commit },
    {
      id,
      startAt,
      endAt,
      isPublic,
      date,
      patientName,
      cancellationReason,
      cancellationSource,
      status,
      canDelete,
      canCancel,
      canEdit,
    }
  ) {
    commit("setTimeForm", {
      id,
      startAt,
      endAt,
      isPublic,
      date,
      patientName,
      cancellationReason,
      cancellationSource,
      status,
      canDelete,
      canCancel,
      canEdit,
    });
    commit("setDialogTime", true);
  },
  async handleEventToAdd({ commit, state, dispatch }) {
    dispatch("loading/openDialog", null, { root: true });
    const selectedDate = state.timeForm.date;
    const appointment = {
      id: state.timeForm.id || null,
      startAt: dayjs(
        `${selectedDate} ${state.timeForm.startAt}`,
        "YYYY-MM-DD HH:mm"
      ).toDate(),
      endAt: dayjs(
        `${selectedDate} ${state.timeForm.endAt}`,
        "YYYY-MM-DD HH:mm"
      ).toDate(),
      isPublic: state.timeForm.isPublic,
    };
    await dispatch("sendAppointmentToApi", appointment);
    dispatch("loading/closeDialog", null, { root: true });
  },
  async sendAppointmentToApi(
    { state, commit },
    { id, startAt, endAt, isPublic }
  ) {
    try {
      if (id) {
        const { data } = await updateAppointment({
          id,
          startAt,
          endAt,
          isPublic: state.patient ? false : isPublic,
          patient: state.patient,
        });
        const index = state.appointments.findIndex(
          (appointment) => appointment.id === id
        );
        commit("replaceAppointment", {
          index,
          appointment: transformDatesStartAndEndToCalendarFormat(data),
        });
      } else {
        const existPatient = state.patient;
        const action = existPatient
          ? createAppointMentWithPatient({
              id,
              startAt,
              endAt,
              patient: state.patient,
            })
          : createAppointment({ startAt, endAt, isPublic });
        const { data } = await action;

        commit(
          "addAppointment",
          transformDatesStartAndEndToCalendarFormat(data)
        );
      }
      commit("setDialogTime", false);
      commit("cleanTimeForm");
      Vue.prototype.$toasted.global.success({
        message: "Agendamento criado com sucesso!",
      });
    } catch (e) {
      Vue.prototype.$errorHandler(e);
    }
  },
  async getAppointmentsFromApi({ commit, dispatch }) {
    try {
      dispatch("loading/openDialog", null, { root: true });
      const { data } = await getAppointmentsByTherapist();
      const formattedAppointments = data.map((appointment) => {
        const { startAt, endAt, ...rest } = appointment;
        return {
          startAt: dayjs(startAt).format("YYYY-MM-DD HH:mm"),
          endAt: dayjs(endAt).format("YYYY-MM-DD HH:mm"),
          ...rest,
        };
      });
      commit("setAppointments", formattedAppointments);
    } catch (error) {
      if (error?.response?.status !== 403) {
        Vue.prototype.$errorHandler(e);
      }
    } finally {
      dispatch("loading/closeDialog", null, { root: true });
    }
  },
  async deleteAppointment({ state, commit, dispatch }) {
    try {
      dispatch("loading/openDialog", null, { root: true });
      const { id } = state.timeForm;
      await deleteAppointment(id);
      const index = state.appointments.findIndex(
        (appointment) => appointment.id === id
      );
      commit("removeAppointment", index);
      commit("setDialogTime", false);
      Vue.prototype.$toasted.global.success({
        message: "Agendamento apagado com sucesso!",
      });
    } catch (e) {
      Vue.prototype.$errorHandler(e);
    } finally {
      dispatch("loading/closeDialog", null, { root: true });
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
