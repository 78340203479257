<template>
  <div>
    <div class="text-center d-flex flex-column align-center">
      <v-img
        :src="imageUrl"
        class="cursor-pointer"
        max-width="80px"
        contain
        @click="openDialog"
      />
      <span class="subtitle-1 grey--text text--darken-3">{{ title }} </span>
      <span v-if="expandImage && src" class="caption grey--text">
        (Clique para expandir)
      </span>
    </div>
    <v-dialog v-model="dialog" :max-width="maxWidth">
      <v-card class="pa-5">
        <div class="">
          <div style="padding-bottom: 10px; display: block">
            <back-button color="grey" @click="dialog = false" />
            <span class="subtitle-1">{{ title }}</span>
          </div>
        </div>
        <v-img :src="imageUrl" cover />
        <div class="text-center mt-5">
          <span v-if="description">{{ description }} </span>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getFile } from "../../services/local/images-service";

export default {
  name: "ImageExpansion",
  props: {
    src: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: [String, Number],
      default: 400,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    expandImage: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dialog: false,
    imageUrl: "",
  }),
  async mounted() {
    this.imageUrl = await this.getImageUrl(this.src);
  },
  methods: {
    openDialog() {
      if (this.expandImage) {
        this.dialog = true;
      }
    },
    async getImageUrl(imageKey) {
      if (imageKey.startsWith("http")) return imageKey;
      if (!imageKey) return "";
      if (imageKey.includes("base64")) return imageKey;

      // Assuming imageKey is the key to retrieve the blob from IndexedDB
      const imageBlob = await getFile(imageKey);
      const url = URL.createObjectURL(imageBlob.blob);

      return url;
    },
  },
};
</script>

<style scoped></style>
