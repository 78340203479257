import db from "@/database";
import { create } from "@/services/external/therapies-service";
import { createOrUpdate, deleteOne } from "@/services/local/therapies-service";
import router from "@/router";
import { insertFileToQueue } from "@/services/external/therapies-integration-queue-service";
import { TherapyStatusEnum } from "@/domains/therapy/shared/application/therapy-status.enum";
import { SEQUENCE } from "@/domains/therapy/steps/mount-sequence/store/sequence/types";
import {
  awsPutImage,
  getSignedConsentTermPresignedUrl,
  getAdditionalFilePresignedUrl,
} from "@/services/external/aws-service";
import { AttachmentTypeEnum } from "@/domains/therapy/shared/application/attachment-type.enum";

const initialState = () => ({
  preTherapyProcedures: "",
  question: {},
  patient: {},
  synced: 0,
  createdAtLocal: new Date(),
  chosenSequence: [],
  step: 1,
  status: TherapyStatusEnum.IN_PROGRESS,
  finishedAt: "",
  signedConsentTerm: null,
  questionsSearched: false,
  additionalFiles: [],
});

const state = {
  therapy: initialState(),
};

const mutations = {
  saveQuestion(state, question) {
    state.therapy.question = Object.assign(state.therapy.question, question);
  },
  savePatient(state, patient) {
    state.therapy.patient = Object.assign(state.therapy.patient, patient);
  },
  saveSequence(state, chosenSequence) {
    state.therapy.chosenSequence = chosenSequence;
  },
  saveDiagnose(state, diagnose) {
    state.therapy.diagnose = diagnose;
  },
  setId(state, id) {
    state.therapy.localId = id;
  },
  changeStep(state, step) {
    state.therapy.step = step;
  },
  changeTherapyToFinished(state) {
    state.therapy.finishedAt = new Date();
    state.therapy.status = TherapyStatusEnum.NOT_SYNCED;
  },
  setTherapy(state, therapy) {
    state.therapy = therapy;
  },
  clearTherapy(state) {
    delete state.therapy.localId;
    Object.assign(state.therapy, initialState());
  },
  setPreTherapyProcedures(state, value) {
    state.therapy.preTherapyProcedures = value;
  },
  setSignedConsentTerm(state, newValue) {
    state.therapy.signedConsentTerm = newValue;
  },
  setQuestionsSearched(state, value) {
    state.therapy.questionsSearched = value;
  },
  setAdditionalFiles(state, value) {
    state.therapy.additionalFiles = value;
  },
};

const actions = {
  async newTherapy({ commit, dispatch }) {
    commit("clearTherapy");
    await dispatch("clearStates");
    router.push({ name: "therapys.stepper" });
  },
  async saveTherapyOffline({ commit, rootGetters, state }) {
    if (state.therapy.step > 1) {
      const diagnosis = rootGetters["diagnosis/diagnose"];
      const sequence = rootGetters["sequence/chosenSequence"];
      commit("saveDiagnose", rootGetters["diagnosis/diagnose"]);
      commit("saveSequence", rootGetters["sequence/chosenSequence"]);
      const updatedTherapyState = {
        ...state.therapy,
        diagnose: {
          ...diagnosis,
          tumoralPhenomena: diagnosis.tumoralPhenomena.map(
            (tumoralPhenomenon) => ({
              ...tumoralPhenomenon,
              relatedPares:
                tumoralPhenomenon.relatedPares?.map((id) => ({
                  id,
                })) || [],
            })
          ),
          potentialSpaces: diagnosis.potentialSpaces.map((potentialSpace) => ({
            ...potentialSpace,
            relatedPares:
              potentialSpace.relatedPares?.map((id) => ({ id })) || [],
          })),
        },
        chosenSequence: sequence,
      };
      console.log("terapy", updatedTherapyState);
      const savedId = await createOrUpdate(updatedTherapyState);
      if (!state.therapy.localId) {
        commit("setId", savedId);
      }
    }
  },
  async syncOneTherapy({ dispatch }, therapy) {
    return new Promise(async (resolve, reject) => {
      try {
        therapy = await dispatch("removeImagesOfObject", therapy);
        console.log("therapy", therapy);
        const therapyAttachments = await dispatch("prepareTherapyAttachments", {
          signedConsentTerm: therapy.signedConsentTerm,
          aditionalFiles: therapy.additionalFiles,
        });

        const { data } = await insertFileToQueue({
          therapyAttachments,
          ...therapy,
        });
        await deleteOne(therapy.localId);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  removeImagesOfObject(context, therapy) {
    therapy.diagnose.tumoralPhenomena = therapy.diagnose.tumoralPhenomena.map(
      (tumoralPhenomenon) => {
        delete tumoralPhenomenon.diagnoseResolutionZone.resolutionZone
          .trackingPoint;
        delete tumoralPhenomenon.diagnoseResolutionZone.resolutionZone
          .impactionPoint;
        return tumoralPhenomenon;
      }
    );
    therapy.diagnose.vascularPhenomena = therapy.diagnose.vascularPhenomena.map(
      (vascularPhenomenon) => {
        delete vascularPhenomenon.diagnoseResolutionZone.resolutionZone
          .trackingPoint;
        delete vascularPhenomenon.diagnoseResolutionZone.resolutionZone
          .impactionPoint;
        return vascularPhenomenon;
      }
    );
    therapy.diagnose.potentialSpaces = therapy.diagnose.potentialSpaces.map(
      (potentialSpace) => {
        delete potentialSpace.diagnoseResolutionZone.resolutionZone
          .trackingPoint;
        delete potentialSpace.diagnoseResolutionZone.resolutionZone
          .impactionPoint;
        return potentialSpace;
      }
    );
    therapy.diagnose.flowTypes = therapy.diagnose.flowTypes.map(
      (diagnoseFlowType) => {
        diagnoseFlowType.pointsOrder = diagnoseFlowType.pointsOrder.map(
          (pointOrder) => {
            const { id } = pointOrder;
            return { id };
          }
        );
        diagnoseFlowType.flowType = { id: diagnoseFlowType.flowType.id };
        return diagnoseFlowType;
      }
    );
    therapy.diagnose.chakras = therapy.diagnose.chakras.map(
      (diagnoseChakra) => {
        delete diagnoseChakra.chakra.image;
        return diagnoseChakra;
      }
    );
    return therapy;
  },

  async finish({ dispatch, commit }) {
    console.log("finish");
    commit("changeTherapyToFinished");
    await dispatch("saveTherapyOffline");
    //await dispatch("clearStates");
  },

  async syncAllTherapys() {
    const therapys = await db.therapy.toArray();
    await create(therapys);
  },

  changeStep({ commit, dispatch }, step) {
    commit("changeStep", step);
  },

  loadTherapy({ commit, dispatch }, therapy) {
    commit("setTherapy", therapy);
    commit(SEQUENCE.MUTATIONS.SET_SELECTED_ITEMS, therapy.chosenSequence, {
      root: true,
    });
    commit("diagnosis/setDiagnose", therapy.diagnose, { root: true });
    dispatch(SEQUENCE.ACTIONS.MOUNT_SEQUENCE, {}, { root: true });
  },

  async deleteTherapy({ commit }, { index, therapy }) {
    await db.therapy.delete(therapy.localId);
  },

  async clearStates({ commit, dispatch }) {
    commit("diagnosis/clearDiagnose", null, { root: true });
    commit("diagnosis/tumoralPhenomena/clear", null, { root: true });
    commit("tumoralPhenomena/clear", null, { root: true });
    commit(SEQUENCE.MUTATIONS.CLEAR_SEQUENCE, null, { root: true });
    dispatch("pares/clearState", null, { root: true });
  },

  async prepareTherapyAttachments({}, { signedConsentTerm, aditionalFiles }) {
    let aditionalFilesUrl = [];
    if (aditionalFiles.length > 0) {
      aditionalFilesUrl = await Promise.all(
        aditionalFiles.map(async (file) => {
          const {
            data: { key, url, resourceUrl },
          } = await getAdditionalFilePresignedUrl(file.type);
          await awsPutImage({
            url,
            key,
            contentType: file.type,
            fileImage: file,
          });
          return {
            type: AttachmentTypeEnum.ADITIONAL_FILE,
            url: resourceUrl,
            originalFileName: file.name,
          };
        })
      );
    }

    let signedConsentTermUrl = [];
    if (signedConsentTerm) {
      const {
        data: { key, url, resourceUrl },
      } = await getSignedConsentTermPresignedUrl(signedConsentTerm.type);
      await awsPutImage({
        url,
        key,
        contentType: signedConsentTerm.type,
        fileImage: signedConsentTerm,
      });
      signedConsentTermUrl = [
        {
          type: AttachmentTypeEnum.SIGNED_CONSENT_TERM,
          url: resourceUrl,
          originalFileName: file.name,
        },
      ];
    }

    return [...signedConsentTermUrl, ...aditionalFilesUrl];
  },
};

const getters = {
  patient(state) {
    return state.therapy.patient;
  },
  chosenSequence(state) {
    return state.therapy.chosenSequence;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
